const getItemFromLocalStorage = (name) => {
	return localStorage.getItem(name);
};
const setItemFromLocalStorage = (name, value) => {
	return localStorage.setItem(name, value);
};
const removeItemFromLocalStorage = (name) => {
	return localStorage.removeItem(name)
};

export {
	getItemFromLocalStorage,
	setItemFromLocalStorage,
	removeItemFromLocalStorage
}
