// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import NumbersReceived from './NumbersReceived';
import {getReceivedNumbers} from "../../../redux/stores/profile";

export default compose(
  connect(
    state => ({
      user: state?.profile?.user,
      receivedNumbers: state?.profile?.receivedNumbers || []
    }),
    dispatch => ({
      getReceivedNumbers: () => dispatch(getReceivedNumbers()),
    }),
  ),
)(NumbersReceived);
