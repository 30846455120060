import React from "react";
import { Store } from 'react-notifications-component';
import getTrans from "../languages/getTrans";

const notification = (
	{
		title = getTrans('common.Системное уведомление'),
		message = '',
		type = 'info'
	}
) => {
	Store.addNotification({
		title: title,
		message: (<React.Fragment>
			<div dangerouslySetInnerHTML={{
				__html: message
			}}/>
		</React.Fragment>),
		type: type,
		insert: 'bottom',
		container: 'bottom-center',
		dismiss: {
			duration: 5000,
			click: true,
			showIcon: true,
			onScreen: false,
			pauseOnHover: false,
			delay: 0
		}
	})
}

const notificationTypes = {
	info: "info",
	success: "success",
	warning: "warning",
	error: "danger",
};

export {
	notification,
	notificationTypes
}
