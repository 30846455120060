// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import TopUpBalance from './TopUpBalance';
import payments, {getPayments} from "../../../redux/stores/payments";

export default compose(
  connect(
    state => ({
      payments: state.payments.payments || [],
      isLoading: state.payments.isLoading || false
    }),
    dispatch => ({
      getPayments: () => dispatch(getPayments()),
    }),
  ),
)(TopUpBalance);
