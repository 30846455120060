import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	FormLabel,
	CircularProgress
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {
	notification,
	notificationTypes
} from "../../../utils/notification";
import {
	getImage
} from "../../../utils/image";
import {
	IMaskInput
} from "react-imask";
import {
	useSelector
} from "react-redux";
import BigNumber from "bignumber.js";
import clsx from "clsx";
import agent from "../../../agent/agent";
import getTrans from "../../../languages/getTrans";

class TopUpBalanceSlug extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedPayment: null,
			selectedPaymentId: props?.match?.params?.id,

			selectedPay: null,

			isBackdrop: false
		};
	}

	componentDidMount = async () => {
		await this.initSelectedPayment();
	}
	initSelectedPayment = async () => {
		let payments = [...(this.props?.payments)];
		if (payments.length <= 0) {
			await this.props.getPayments();
		}
		payments = [...(this.props?.payments)];

		const { selectedPaymentId } = this.state;
		const selectedPayment = payments.find((t) => Boolean(String(t?.id) === String(selectedPaymentId)));
		if (!selectedPayment) {
			notification({
				type: notificationTypes.error,
				message: getTrans('balance.Платежная система не найден')
			})
			this.props.history.replace('/top-up-balance');
			return
		}

		await this.setState({
			selectedPayment,
			selectedPay: selectedPayment?.payInfo?.[0] || null
		})
	}

	changeSelectedPay = async (selectedPay) => {
		await this.setState({ selectedPay });
	}
	createLinkPayment = async (amount) => {
		await this.setState({ isBackdrop: true });

		const { user, locale } = this.props;
		const { selectedPay } = this.state;

		const body = {
			"options": {},
			"service_id": 1,
			"amount": amount,
			"user_id": user?.id,
			"payment_method_id": selectedPay?.paymentMethodId,
			"successUrl": `https://t.me/GrizzlyMiniAppSitisitbot/play?startapp=payments`
		};
		if (selectedPay?.paymentMethodType && selectedPay?.paymentMethodTypeAttribute) {
			body.options[selectedPay.paymentMethodTypeAttribute] = selectedPay.paymentMethodType;
		}

		let queryParams = [];
		if (locale === 'ru') {
			queryParams.push(`config=7grizzlysms.com`);
		} else {
			queryParams.push(`config=grizzlysms.com`);
		}

		const res = await agent.post(`/api/payment-intents?${ queryParams.join('&') }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			const data = err?.response?.data;
			let errMessage = "";
			if (data?.[0]?.message) {
				errMessage = data?.[0]?.message;
			}
			return {error: errMessage || getTrans('errors.Ошибка сервера')}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			notification({
				message: res?.error,
				type: notificationTypes.error
			})
			return
		}

		await this.setState({ isBackdrop: false });

		const linkElement = document.createElement('a');
		linkElement.id = 'buttonRoutePayment'
		linkElement.href = res?.pay_url;
		linkElement.target = '_blank';
		document.body.appendChild(linkElement);
		document.getElementById('buttonRoutePayment').click();
		document.getElementById('buttonRoutePayment').remove();
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			selectedPay,
			selectedPayment,

			isBackdrop
		} = this.state;

		if (!selectedPayment) {
			return null
		}
		return (
			<Box className={classes.root}>
				<Box className={classes.section}>

					<Header
						name={ selectedPayment?.name }
						imageId={ selectedPayment?.image_id }
					/>

					<Box mt={2}/>

					<TypesPayment
						selected={selectedPay}
						items={selectedPayment?.payInfo || []}
						onChange={this.changeSelectedPay}
					/>

					{Boolean(selectedPay) && (
						<FormTopUpBalance
							info={selectedPay}
							onCreate={this.createLinkPayment}
						/>
					)}

				</Box>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		);
	}
};
const Header = (props) => {
	const {
		name,
		imageId
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.header}>
			<img src={getImage(imageId, { height: 40, fit: "fill" })} className={classes.headerLogo}/>
			<div className={classes.headerName}>{ name }</div>
		</Box>
	)
};
const TypesPayment = React.memo((props) => {
	const {
		items,
		selected,
		onChange,
	} = props;
	const classes = useStyles();

	return (
		<Grid container spacing={1}>
			{items.map((_item) => (
				<Grid key={`_item-${ _item.paymentMethodId }`} item xs={4}>
					<Box
						className={clsx({
							[classes.typePaymentCard]: true,
							"--selected": Boolean(selected?.paymentMethodId === _item.paymentMethodId)
						})}
						onClick={onChange.bind(null, _item)}
					>
						<img src={getImage(_item.image_id, { fit: "fill", height: 64 })}/>
						<div>{_item.paymentMethodName}</div>
					</Box>
				</Grid>
			))}
		</Grid>
	)
});
const FormTopUpBalance = React.memo((props) => {
	const {
		info,
		onCreate
	} = props;
	const classes = useStyles();
	const minValue = Number.parseFloat(info?.minValue || 0);
	const usdRate = useSelector((state) => state.currency?.currencies?.usd || 0);
	const commissionValue = info?.commissionValue || 0;

	React.useEffect(() => {
		handleChangeAmount({
			target: {
				value: info?.minValue
			}
		})
	}, [info])

	const [amountCommission, setAmountCommission] = React.useState('');
	const handleChangeAmountCommission = ({ target }) => {
		const _value = target?.value || 0;
		setAmountCommission(String(_value));

		let _amount = handleCalcAmountWithoutCommission(Number.parseFloat(_value));
		if (_amount <= 0) {
			_amount = ""
		}
		setAmount(String(_amount));
	};
	const handleCalcAmountWithoutCommission = (_amount) => {
		_amount = new BigNumber(_amount).minus(new BigNumber(_amount).dividedBy(100).multipliedBy(info.commissionPercentage || 0).toNumber()).decimalPlaces(2,1).toNumber();
		return new BigNumber(_amount).minus(usdRate * commissionValue).decimalPlaces(2,0).toNumber()
	}

	const [amount, setAmount] = React.useState('');
	const handleChangeAmount = ({ target }) => {
		const _value = String(target?.value || 0);
		setAmount(_value);

		const _amountCommission = handleCalcAmountCommission(Number.parseFloat(_value));
		setAmountCommission(String(_amountCommission));
	};
	const handleCalcAmountCommission = (_amount) => {
		const _fixed = Number.parseFloat(info?.commissionValue || 0) * usdRate;
		const _percent = Number.parseFloat(info?.commissionPercentage || 0);
		let _commision = Number.parseFloat((_amount / (100 - _percent)) * 100);
		_commision = _commision + _fixed;
		return new BigNumber(_commision).decimalPlaces(2,1).toNumber().toString()
	}

	const handleCreateOrder = () => {
		onCreate(amountCommission)
	}

	const _commissionMessage = () => {
		if (!info) {
			return ""
		}

		return [
			Boolean(info?.commissionPercentage) && `${info?.commissionPercentage}%`,
			Boolean(info?.commissionValue) && `${info?.commissionValue}$`,
		].filter((t) => !!t).join(" + ")
	}
	const _messageDisabledButton = () => {
		if (Number.parseFloat(amountCommission || 0) < minValue) {
			return `${getTrans('balance.Сумма пополнения должна быть больше')} ${ minValue }₽`
		}
		return null
	}

	if (!info) {
		return null
	}
	return (
		<>
			<Box mt={3}/>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<div className={classes.information}>
						<div className="--label">{getTrans('balance.Минимальный платёж')}</div>
						<div className="--value">{info.minValue}₽</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.information}>
						<div className="--label">{getTrans('balance.Комиссия')}</div>
						<div className="--value">{_commissionMessage()}</div>
					</div>
				</Grid>
			</Grid>
			<Box mt={2}/>
			<Grid container spacing={1}>
				{[...(info?.buttonsPaymentsAmount || [])].map((_amountVal) => (
					<Grid item>
						<Button
							size="small"
							color="secondary"
							variant="contained"
							onClick={handleChangeAmount.bind(null, {target: {value: _amountVal}})}
						>{_amountVal} ₽</Button>
					</Grid>
				))}
			</Grid>
			<Box mt={2}>
				<div className={classes.formLabel}>
					{getTrans('balance.Сумма к оплате с учётом комиссии')}
				</div>
				<TextField
					value={amountCommission}
					fullWidth
					variant="outlined"
					placeholder="0,00"
					InputProps={{inputComponent: CustomInputAmount}}
					inputProps={{scale: 2}}
					onChange={handleChangeAmountCommission}
				/>
			</Box>
			<Box mt={2}>
				<div className={classes.formLabel}>
					{getTrans('balance.Будет зачислено')},₽
				</div>
				<TextField
					value={amount}
					fullWidth
					variant="outlined"
					placeholder="0,00"
					InputProps={{inputComponent: CustomInputAmount}}
					inputProps={{scale: 2}}
					onChange={handleChangeAmount}
				/>
			</Box>
			<Box mt={2}/>
			{Boolean(_messageDisabledButton()) && (
				<div
					className={classes.formErrorMessage}
					dangerouslySetInnerHTML={{
						__html: _messageDisabledButton()
					}}
				/>
			)}
			<Button
				color="secondary"
				variant="contained"
				className={classes.formSubmit}
				fullWidth
				disabled={_messageDisabledButton()}
				onClick={handleCreateOrder}
			>{getTrans('balance.Оплатить')}</Button>
		</>
	)
});

const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		{...rest}
		mask={Number}
		thousandsSeparator=""
		radix="."
		mapToRadix={[',']}
		scale={rest?.scale || 0}
		unmask={true}
	/>
)

const styles = (theme) => ({
	root: {
		padding: 12,
		boxSizing: "border-box"
	},
	section: {
		padding: "15px 12px",
		borderRadius: 12,
		backgroundColor: theme.palette.background.section,
	}
});
TopUpBalanceSlug = withStyles(styles)(TopUpBalanceSlug);

const useStyles = makeStyles((theme) => ({

	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	headerLogo: {
		height: 40,
		objectFit: "contain",
		marginBottom: 15
	},
	headerName: {
		fontSize: 20,
		lineHeight: "100%",
		color: theme.palette.text.main,
		textAlign: "center",
		fontWeight: "700"
	},

	typePaymentCard: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: 10,
		borderRadius: 5,
		boxSizing: "border-box",
		backgroundColor: "rgba(255,255,255,0.1)",

		"& img": {
			width: 28,
			height: 28,
			marginBottom: 8,
			objectFit: "cover",
			borderRadius: 4
		},
		"& div": {
			display: "-webkit-box",
			"-webkit-line-clamp": 1,
			"-webkit-box-orient": "vertical",
			overflow: "hidden",

			fontSize: 12,
			lineHeight: "120%",
			color: theme.palette.text.main,
			fontWeight: "600",
			textAlign: "center",
		},
		"&.--selected": {
			backgroundColor: theme.palette.secondary.main
		}
	},

	information: {
		display: "flex",
		flexDirection: "column",

		"& .--label": {
			marginBottom: 4,
			fontSize: 12,
			lineHeight: "15px",
			color: "#767D88",
			fontWeight: "500"
		},
		"& .--value": {
			fontSize: 15,
			lineHeight: "18px",
			color: "#E7E6E6",
			fontWeight: "700"
		},
	},

	formLabel: {
		marginBottom: 8,
		fontSize: 15,
		lineHeight: "18px",
		color: "#767D88",
		fontWeight: "600"
	},
	formSubmit: {
		padding: 12,

		fontSize: 18,
		lineHeight: "100%",
		fontWeight: "700"
	},
	formErrorMessage: {
		marginBottom: 4,
		fontSize: 13,
		lineHeight: "15px",
		color: theme.palette.error.main
	}

}));

export default TopUpBalanceSlug
