import React from "react";
import {
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const InDeveloper = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<img
				src={require("../../assets/png/common/in-develop.png").default}
				className={classes.image}
			/>
			<Typography className={classes.title}>
				В разработке
			</Typography>
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: 40,
		boxSizing: "border-box",
		gap: 10
	},
	image: {
		width: "100%",
		maxWidth: "124px",
	},
	title: {
		fontSize: 24,
		lineHeight: "100%",
		color: "white",
		textAlign: "center",
		fontWeight: "600"
	}
}));

export default InDeveloper
