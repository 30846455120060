import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Typography,
} from "@mui/material";
import {
	withStyles,
	makeStyles
} from "@mui/styles";
import {
	Add as AddIcon,
	Remove as RemoveIcon
} from "@mui/icons-material";
import {
	FormFeedBack
} from "../../../components"
import agent from "../../../agent/agent";
import clsx from "clsx";
import getTrans from "../../../languages/getTrans";

class Faq extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tags: [],
			items: [],

			selectedTag: null
		};
	}

	componentDidMount = async () => {
		await this.getFaqItems()
	}

	getFaqItems = async () => {
		const res = await agent.get('/api/cms/page-by-path?path=/faq').then((res) => {
			return res.data?.placeholders?.content || []
		}).catch((err) => {
			return []
		});
		const block = res.find((t) => t.block_name === 'FaqBlock');
		if (!block) {
			return null
		}

		const { tags, items } = block?.extras || {};
		this.setState({
			tags: tags || [],
			items: items || [],
			selectedTag: tags?.[0]?.id
		});
	}
	changeSelectedTag = (selectedTag) => {
		this.setState({ selectedTag })
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			tags,
			items,
			selectedTag
		} = this.state;

		return (
			<>
				<Box className={classes.header}>
					<Typography className={classes.title}>
						{getTrans('faq.Часто задаваемые вопросы')}
					</Typography>
					<Grid container spacing={1}>
						{tags.map((_tag) => (
							<Grid key={`_tag-${ _tag.id }`} item>
								<Button
									size="small"
									className={clsx({
										[classes.buttonTag]: true,
										'--selected': Boolean(_tag.id === selectedTag)
									})}
									onClick={this.changeSelectedTag.bind(null, _tag.id)}
								>{ _tag?.title }</Button>
							</Grid>
						))}
					</Grid>
				</Box>

				<Grid container spacing={1}>
					{[...items]
						.filter((t) => Boolean(String(t?.tag_id) === String(selectedTag)))
						.map((faqItem) => (
							<Grid key={`faqItem-${ faqItem.id }`} item xs={12}>
								<ItemFaq title={faqItem.question} message={faqItem?.answer}/>
							</Grid>
						))
					}
				</Grid>

				<Box mt={2}/>
				<FormFeedBack/>
			</>
		);
	}
};
const ItemFaq = React.memo(({ title, message }) => {
	const classes = useStyles();

	const [isOpen, setOpen] = React.useState(false);
	const IconOpen = Boolean(isOpen) ? RemoveIcon : AddIcon;
	const handleChangeOpen = () => {
		setOpen(!isOpen)
	};

	return (
		<div className={classes.itemFaq}>
			<div className={classes.itemFaqHead} onClick={handleChangeOpen}>
				<div dangerouslySetInnerHTML={{
					__html: title
				}}/>
				<IconOpen/>
			</div>
			{Boolean(isOpen) && (
				<div className={classes.itemFaqBody}>
					<div dangerouslySetInnerHTML={{
						__html: message
					}}/>
				</div>
			)}
		</div>
	)
})
const useStyles = makeStyles((theme) => ({
	itemFaq: {
		background: theme.palette.background.section,
	},
	itemFaqHead: {
		display: "flex",
		alignItems: "center",
		padding: "14px 12px",
		boxSizing: "border-box",

		fontSize: 15,
		lineHeight: "120%",
		fontWeight: "600",
		color: theme.palette.text.main,

		"& p": {
			margin: 0
		},
		"& > div": {
			flex: 1
		},
		"& svg": {
			color: theme.palette.secondary.main
		}
	},
	itemFaqBody: {
		padding: "14px 12px",
		paddingTop: 0,
		boxSizing: "border-box",

		fontSize: 13,
		lineHeight: "180%",
		color: theme.palette.text.main,
		opacity: 0.6,

		"& a": {
			color: theme.palette.text.link,
		},
		"& img": {
			maxWidth: "100%!important",
			height: "auto!important"
		}
	},
}));

const styles = (theme) => ({
	header: {
		padding: "12px",
		boxSizing: "border-box"
	},
	title: {
		marginBottom: 10,

		fontSize: 18,
		lineHeight: "120%",
		fontWeight: "600",
		color: theme.palette.text.main,
	},

	buttonTag: {
		border: "1px solid #FB780066",
		padding: "5px 9px",

		background: "#FB780033",
		fontSize: 12,
		lineHeight: "120%",
		color: "white",

		"&.--selected": {
			background: "#FB7800",
			borderColor: "#FB7800",
		}
	}
});
Faq = withStyles(styles)(Faq);

export default Faq
