import React from "react";
import {
	Box,
	Grid,
	TextField,
	Typography,
	InputAdornment
} from "@mui/material";
import {
	makeStyles, useTheme
} from "@mui/styles";
import {
	CloseRounded as CloseRoundedIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../components";
import {useDispatch, useSelector} from "react-redux";
import {getImage} from "../../../../../utils/image";
import theme from "../../../../../theme";
import {changeSelectedService} from "../../../../../redux/stores/services";
import {LazyLoadImage} from "react-lazy-load-image-component";
import clsx from "clsx";
import getTrans from "../../../../../languages/getTrans";

let timeOutSearch = null;
const Services = (props) => {
	const theme = useTheme();
	const classes = useStyles();

	const initServices = useSelector((state) => (state?.services?.services || []));
	const [services, setServices] = React.useState([...initServices]);
	const handleSetSearchServices = (_val) => {
		const _search = String(_val || '').toLowerCase();
		if (!_search) {
			setServices([...initServices]);
			return
		}

		setServices([...(initServices || [])]
			.filter((service) => {
				const name = String(service?.name || '').toLocaleLowerCase();
				const nameEnRu = String(service?.nameTranscriptionEnRu || '').toLocaleLowerCase();
				const nameRuEn = String(service?.nameTranscriptionRuEn || '').toLocaleLowerCase();
				const slug = String(service?.slug || '').toLocaleLowerCase();
				if (
					name.includes(_search) ||
					slug.includes(_search) ||
					nameEnRu.includes(_search) ||
					nameRuEn.includes(_search)
				) {
					return true
				}
				return false
			}))
	};
	React.useEffect(() => {
		setServices([...initServices]);
	}, [initServices]);

	const [search, setSearch] = React.useState("");
	const handleResetSearch = () => {
		setSearch('');
		handleSetSearchServices('');
	};
	const handleChangeSearch = ({ target }) => {
		clearTimeout(timeOutSearch);
		setSearch(target.value);
		timeOutSearch = setTimeout(() => {
			handleSetSearchServices(target.value);
		}, 500);
	};

	const selectedService = useSelector((state) => state.services.selectedService);
	const [nameSelectedService, setNameSelectedService] = React.useState('');
	React.useEffect(() => {
		const _name = initServices.find((service) => Boolean(String(service.id) === String(selectedService)))?.name || "";
		setNameSelectedService(_name);
	}, [selectedService]);

	return (
		<Box className={classes.section}>
			<Grid container spacing={2} alignItems="center" justifyContent="space-between">
				<Grid item>
					<div className={classes.title}>
						{getTrans('home.Выбор сервиса')}
					</div>
				</Grid>
				<Grid item>
					<TextField
						value={search}
						fullWidth
						variant="outlined"
						placeholder={nameSelectedService}
						InputProps={{endAdornment: <InputAdornment position="end" onClick={handleResetSearch}>
								<CloseRoundedIcon sx={{color: theme.palette.text.main, opacity: Boolean(!!search) ? 1 : 0}}/>
						</InputAdornment>}}
						onChange={handleChangeSearch}
						className={classes.inputSearch}
					/>
				</Grid>
			</Grid>
			<Box mt={1}/>
			<Box className={classes.sectionBody}>
				<Grid
					container
					spacing={1}
				>{services.map((service) => (
					<Grid key={`service-${ service.id }`} item xs={6}>
						<ServiceCard {...service}/>
					</Grid>
				))}</Grid>
			</Box>

			<VisibleContent visible={Boolean(!!search && services.length <= 0)}>
				<div className={classes.notFound}>
					{getTrans('common.По вашему запросу ничего не найдено')}
				</div>
			</VisibleContent>
		</Box>
	)
};
const ServiceCard = React.memo((service) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const selectedService = useSelector((state) => state.services.selectedService);
	const isSelected = Boolean(String(service.id) === String(selectedService));

	const handleClick = async () => {
		await dispatch(changeSelectedService(service.id));
	};

	return (
		<Box
			className={clsx({
				[classes.serviceCard]: true,
				[classes.serviceCardSelected]: isSelected,
			})}
			onClick={handleClick}
		>
			<Box className={classes.serviceIcon}>
				<LazyLoadImage
					width={32}
					height={32}
					src={getImage(service.icon_id, {
						width: 32,
						height: 32,
						fit: "fill"
					})}
				/>
			</Box>
			<Box className={classes.serviceName}>
				{ service.name }
			</Box>
		</Box>
	)
});

const useStyles = makeStyles((theme) => ({
	section: {
		backgroundColor: theme.palette.background.section,
		padding: "9px 8px",
		boxSizing: "border-box"
	},
	sectionBody: {
		overflow: "auto",
		maxHeight: "calc(100vh / 100 * 24)",
	},
	title: {
		fontSize: 18,
		lineHeight: "100%",
		fontWeight: "600",
		color: theme.palette.text.main,
	},
	notFound: {
		fontSize: 16,
		lineHeight: "120%",
		color: theme.palette.text.caption
	},
	inputSearch: {
		maxWidth: 200,
		"& .MuiInputAdornment-root": {
			height: 18
		},
		"& .MuiInputBase-root": {
			padding: "3px 8px",
			boxSizing: "border-box",
			borderRadius: "6px",
			fontSize: "14px",
			lineHeight: "16px",
			fontWeight: "500",
			border: "1px solid rgba(255,255,255,0.1)",
		},
		"& .MuiInputBase-input": {
			padding: 0
		},
		"& .MuiSvgIcon-root": {
			width: 18,
			height: 18,
		}
	},

	serviceCard: {
		display: "flex",
		alignItems: "center",
		padding: 5,
		boxSizing: "border-box",
		background: theme.palette.background.secondary,
		border: "1.5px solid",
		borderColor: theme.palette.background.secondary,
		borderRadius: 8
	},
	serviceCardSelected: {
		borderColor: "#FB7800"
	},
	serviceIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 26,
		height: 26,
		borderRadius: 4,
		position: "relative",
		overflow: "hidden",

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	serviceName: {
		flex: 1,
		marginLeft: 6,

		fontSize: 13,
		lineHeight: "100%",
		fontWeight: "500",
		color: theme.palette.text.main
	},
}));

export default Services
