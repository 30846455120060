import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Skeleton
} from "@mui/material";
import {
	Head,
	ItemCard
} from "./components";
import {
	VisibleContent
} from "../../../components";
import agent from "../../../agent/agent";
import getTrans from "../../../languages/getTrans";

class BalanceHistory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			filter: {
				type: "incoming",
				page: 1
			},

			isLoading: true,
			isShowMore: false,
		};
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async ({ isMore } = {}) => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/api/sms-users/get-transactions${ filter }`).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		});

		let items = [];
		if (isMore) {
			items = [...(this.state?.items || [])]
		}

		await this.setState({
			items: [...items, ...res],
			isLoading: false,
			isShowMore: Boolean(res.length === 20)
		});
	}
	loadMoreItems = async () => {
		const filter = {...this.state.filter};
		filter.page = filter.page + 1;
		await this.setState({ filter });
		await this.getItems({ isMore: true })
	}

	getFilter = () => {
		const { filter } = this.state;

		let _str = [];
		Object.keys(filter).map((_key) => {
			let _val = filter[_key];
			if (_val) {
				_str.push(`${ _key }=${ _val }`);
			}
		})

		return `?${ _str.join('&') }`
	}
	changeFilter = async (filter) => {
		await this.setState({
			items: [],
			filter
		});
		await this.getItems();
	}

	render() {
		const {
			items,
			filter,
			isLoading,
			isShowMore,
		} = this.state;

		return (
			<>
				<Head
					filter={filter}
					onChange={this.changeFilter}
				/>
				<Box mt={1}/>
				<Grid container spacing={1}>
					{items.map((item) => (
						<Grid key={`item-${ filter.type }-${ item.id }`} item xs={12}>
							<ItemCard item={item} type={filter.type}/>
						</Grid>
					))}
					<VisibleContent visible={isLoading}>
						<Grid item xs={12}>
							<Skeleton sx={{ transform: "initial", height: 40 }}/>
						</Grid>
						<Grid item xs={12}>
							<Skeleton sx={{ transform: "initial", height: 40 }}/>
						</Grid>
						<Grid item xs={12}>
							<Skeleton sx={{ transform: "initial", height: 40 }}/>
						</Grid>
					</VisibleContent>
					<VisibleContent visible={isShowMore}>
						<Grid item xs={12}>
							<Button
								fullWidth
								sx={{ height: 36, fontSize: 14, borderRadius: 0 }}
								variant="contained"
								onClick={this.loadMoreItems}
							>{getTrans('common.Показать еще')}</Button>
						</Grid>
					</VisibleContent>
				</Grid>
			</>
		);
	}
}

export default BalanceHistory
