import agent from "../../agent/agent";
import dictionaryRuEn from "../../constants/dictionary-ru-en.json";
import dictionaryEnRu from "../../constants/dictionary-en-ru.json";
import {getItemFromLocalStorage} from "../../utils/localstorage";

const GET_COUNTRIES = "profile/GET_COUNTRIES";
const CHANGE_LOADING = "profile/CHANGE_LOADING";

const initStage = {
	countries: [],
	isLoadingCountries: true,
};

export function getCountries () {
	return async function (dispatch, getState) {

		dispatch({ type: CHANGE_LOADING, value: true });
		const state = getState();
		const user = state?.profile?.user;
		const config = state?.config?.config;

		let serviceId = getItemFromLocalStorage('selectedService');
		if (!serviceId) {
			serviceId = String(config?.var?.['Сервис выбора по умолчанию'])
		}

		const userId = user?.id || 'guest';
		const isWholesale = false;

		const res = await agent.get(`/api/country/get-prices/${ Number.parseFloat(serviceId) }?page=1&wholesale=${isWholesale}&user=${userId}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		dispatch({
			type: GET_COUNTRIES,
			value: res
		})

	}
}

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_COUNTRIES: {
			return {
				...state,
				countries: action?.value || [],
				isLoadingCountries: false
			}
		}
		case CHANGE_LOADING: {
			return {
				...state,
				isLoadingCountries: action?.value || false
			}
		}
		default:
			return state;
	}
}
