import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	IconButton,
	Typography,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Autorenew as AutorenewIcon
} from "@mui/icons-material";
import {
	PersonalInformationSmall
} from "../../../components";
import {
	NumberCard
} from "./components";
import api from "js-cookie";
import agent from "../../../agent/agent";
import {notification, notificationTypes} from "../../../utils/notification";
import getTrans from "../../../languages/getTrans";

class NumbersReceived extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dateTimeLastUpdate: null
		};
		this.timeoutCheckUpdate = null;
	}

	componentDidMount = async () => {
		await this.props.getReceivedNumbers();
		await this.watchUpdateGetNumbers();
	}
	componentWillUnmount = () => {
		clearTimeout(this.timeoutCheckUpdate);
	}

	watchUpdateGetNumbers = async () => {
		clearTimeout(this.timeoutCheckUpdate);

		const { user } = this.props;
		const { dateTimeLastUpdate } = this.state;
		const apiKey = user?.settings?.apiKey;
		const apiHost = String(process.env.REACT_APP_HOST_API || '').replace('https://', 'https://api.');

		const _dateTimeLastUpdate = await agent.get([apiHost, '/sms/phone-number/lastupdate?api_key=', apiKey].join('')).then((res) => {
			const _data = res.data;
			return Boolean(_data?.status === 200) ? _data?.data || null : null
		}).catch(() => {
			return null
		});
		if (_dateTimeLastUpdate !== dateTimeLastUpdate && dateTimeLastUpdate && _dateTimeLastUpdate) {
			await this.props.getReceivedNumbers();
		}

		await this.setState({
			dateTimeLastUpdate: _dateTimeLastUpdate
		})
		this.timeoutCheckUpdate = setTimeout(async () => {
			await this.watchUpdateGetNumbers();
		}, (10 * 1000));
	}

	render() {
		const {
			classes,
			receivedNumbers,
			getReceivedNumbers
		} = this.props;

		return (
			<>
				<PersonalInformationSmall/>
				<Box mt={1}/>

				<Box className={classes.section}>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="sectionTitle">
								{getTrans('my_numbers.Полученные номера')}
							</Typography>
						</Grid>
						<Grid item>
							<Button
								color="secondary"
								className={classes.buttonUpdate}
								onClick={getReceivedNumbers}
							>
								<AutorenewIcon/>
							</Button>
						</Grid>
					</Grid>
					<Box mt={2}/>
					<Grid container spacing={3}>
						{receivedNumbers.map((item) => (
							<Grid item xs={12}>
								<NumberCard
									key={`NumberCard-${ item.id }`}
									item={item}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</>
		);
	}
};

const styles = theme => ({
	section: {
		padding: "14px 12px",
		boxSizing: "border-box",
		background: theme.palette.background.section
	},
	sectionTitle: {
		display: "block",
		marginBottom: "20px!important",
	},
	buttonUpdate: {
		width: 28,
		height: 28,
		borderRadius: 4,
		padding: 2,
		minWidth: "initial",
		background: `${theme.palette.secondary.main}!important`,

		color: "white",

		"& svg": {
			width: "100%",
			height: "100%",
		}
	}
});
NumbersReceived = withStyles(styles, { withTheme: true })(NumbersReceived);

export default NumbersReceived
