import {palette} from "../common";

export default {
	styleOverrides: {

		root: {
			padding: "6px 12px",
			boxSizing: "border-box",
			borderRadius: 6,
			boxShadow: "none",

			fontSize: 12,
			lineHeight: "120%",
			textTransform: "initial",

			"&.Mui-disabled": {
				backgroundColor: "#8E8E8E",
				color: "#FFFFFF"
			}
		},

		containedSecondary: {},

		containedPrimary: ({ ownerState, theme }) => ({
			backgroundColor: theme.palette.primary.main,
		}),

		containedError: {
			color: "white"
		},
		containedSuccess: {
			color: "white"
		},

		colorInherit: {
			backgroundColor: "#F7F7F7"
		},

		outlined: {
			borderColor: palette.primary.main,
			color: palette.primary.main
		}
	}
}
