import React from "react";
import {
	Box,
	Grid,
	Button,
	Skeleton,
	TextField,
	InputAdornment
} from "@mui/material";
import {
	useTheme,
	makeStyles
} from "@mui/styles";
import {
	Search as SearchIcon,
	CloseRounded as CloseRoundedIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../components";
import {useSelector} from "react-redux";
import {getImage} from "../../../../../utils/image";
import {convertorNumber} from "../../../../../utils/convertor";
import {LazyLoadImage} from "react-lazy-load-image-component";
import clsx from "clsx";
import getTrans from "../../../../../languages/getTrans";

let timeOutSearch = null;
const Countries = (props) => {
	const theme = useTheme();
	const classes = useStyles();

	const isLoading = useSelector((state) => state.countries.isLoadingCountries);

	const initCountries = useSelector((state) => state?.countries?.countries || []);
	const [countries, setCountries] = React.useState([...initCountries]);
	const handleSetFilterCountries = (searchValue) => {
		let _search = String(searchValue || '').toLowerCase();
		if (!_search) {
			setCountries([...initCountries]);
			return
		}

		let _countries = [...(initCountries || [])].filter((country) => {
			const _slug = String(country?.country_slug || '').toLowerCase();
			const _name = String(country?.name || '').toLowerCase();

			return Boolean(
				_slug.includes(_search) ||
				_name.includes(_search)
			)
		});
		setCountries(_countries);
	};
	React.useEffect(() => {
		setCountries([...initCountries]);
	}, [initCountries]);

	const [search, setSearch] = React.useState("");
	const handleResetSearch = () => {
		setSearch('');
		handleSetFilterCountries('');
	};
	const handleChangeSearch = ({ target }) => {
		clearTimeout(timeOutSearch);
		setSearch(target.value);
		timeOutSearch = setTimeout(() => {
			handleSetFilterCountries(target.value);
		}, 500);
	};

	return (
		<Box className={classes.section}>
			<Grid container spacing={2} alignItems="center" justifyContent="space-between">
				<Grid item>
					<div className={classes.title}>
						{getTrans('home.Выбор стран')}
					</div>
				</Grid>
				<Grid item>
					<TextField
						value={search}
						fullWidth
						variant="outlined"
						className={classes.inputSearch}
						placeholder={getTrans('home.Поиск по стране')}
						InputProps={{endAdornment: <InputAdornment position="end" onClick={handleResetSearch}>
								<CloseRoundedIcon sx={{color: theme.palette.text.main, opacity: Boolean(!!search) ? 1 : 0}}/>
						</InputAdornment>}}
						onChange={handleChangeSearch}
					/>
				</Grid>
			</Grid>
			<Box mt={1}/>
			<Box className={classes.sectionBody}>
				<Grid container spacing={1}>
					<VisibleContent visible={Boolean(!isLoading)}>
						{countries.map((country) => (
							<Grid key={`country-${ country.id }`} item xs={12}>
								<CountryCard
									{...country}
									onBuy={props.onBuyNumber.bind(null, country)}
								/>
							</Grid>
						))}
					</VisibleContent>
					<VisibleContent visible={Boolean(isLoading)}>
						{new Array(6).fill(0).map((_, i) => (
							<Grid key={`loading-${ i }`} item xs={12}>
								<Skeleton className={classes.skeleton}/>
							</Grid>
						))}
					</VisibleContent>
				</Grid>
				<VisibleContent visible={Boolean(!isLoading && countries.length <= 0)}>
					<div className={classes.notFound}>
						{getTrans('commom.По вашему запросу ничего не найдено')}
					</div>
				</VisibleContent>
			</Box>
		</Box>
	)
};
const CountryCard = React.memo((country) => {
	const classes = useStyles();
	const isRussia = Boolean(String(country?.country_external_id) === '0');
	const isDisabled = !Boolean(country?.button_status);

	const _infoItems = () => {
		return [
			[
				Boolean(country?.count > 0) ? convertorNumber(country?.count) : getTrans('common.мало'),
				Boolean(country?.count > 0) ? getTrans('common.шт') : ''
			].filter((t) => !!t).join(" "),

			[convertorNumber(country?.price), '₽'].join(" "),
		]
	}
	const _disabledMessage = () => {
		if (isRussia) {
			return getTrans('countries.Телефонные номера из России не продаются')
		}
		if (isDisabled) {
			return getTrans('countries.Номера временно отсутствуют')
		}
		return ""
	}

	return (
		<Box
			className={clsx({
				[classes.countryCard]: true,
				'--disabled': isRussia || isDisabled
			})}
		>
			<Box className={classes.countryMain}>
				<Box className={classes.countryIcon}>
					<LazyLoadImage
						width={32}
						height={32}
						src={getImage(country.icon_id, {
							width: 32,
							height: 32,
							fit: "fill"
						})}
					/>
				</Box>
				<Box className={classes.countryName}>
					{ country?.name }
				</Box>
			</Box>
			<Box className={classes.countryBody}>
				{_infoItems().map((_item, index) => (
					<div dangerouslySetInnerHTML={{ __html: _item }}/>
				))}
			</Box>
			<Box className={classes.countryButton}>
				<Button
					color="secondary"
					variant="contained"
					onClick={country.onBuy}
				>{getTrans('common.Получить')}</Button>
			</Box>

			{Boolean(_disabledMessage()) && (
				<Box className={classes.countryDisabledMessage}>
					{_disabledMessage()}
				</Box>
			)}
		</Box>
	)
});

const useStyles = makeStyles((theme) => ({
	section: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		backgroundColor: theme.palette.background.section,
		padding: "9px 8px",
		boxSizing: "border-box"
	},
	sectionBody: {
		flex: 1,
		overflow: "auto",
		position: "relative",

		"& > .MuiGrid-container": {
			position: "absolute",
			top: 0, left: 0,
		}
	},
	title: {
		fontSize: 18,
		lineHeight: "100%",
		fontWeight: "600",
		color: theme.palette.text.main,
	},
	notFound: {
		fontSize: 16,
		lineHeight: "120%",
		color: theme.palette.text.caption
	},
	inputSearch: {
		maxWidth: 200,
		"& .MuiInputAdornment-root": {
			height: 18
		},
		"& .MuiInputBase-root": {
			padding: "3px 8px",
			boxSizing: "border-box",
			borderRadius: "6px",
			fontSize: "14px",
			lineHeight: "16px",
			fontWeight: "500",
			border: "1px solid rgba(255,255,255,0.1)",
		},
		"& .MuiInputBase-input": {
			padding: 0
		},
		"& .MuiSvgIcon-root": {
			width: 18,
			height: 18,
		}
	},

	countryCard: {
		display: "flex",
		alignItems: "center",
		gap: 16,
		padding: 6,
		boxSizing: "border-box",
		background: theme.palette.background.secondary,
		borderRadius: 8,

		"&.--disabled": {
			position: "relative",
			overflow: "hidden",
			"&:before": {
				content: "''",
				position: "absolute",
				top: 0, left: 0,
				zIndex: 2,
				width: "100%",
				height: "100%",
				backgroundColor: theme.palette.primary.main,
				opacity: 0.8,
				borderRadius: 8,
			},
		},
	},
	countryMain: {
		display: "flex",
		alignItems: "center",
		flex: 1,
	},
	countryIcon: {
		width: 26,
		height: 26,
		borderRadius: 4,
		overflow: "hidden",
		position: "relative",

		"& img": {
			position: "absolute",
			top: 0, left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	countryName: {
		flex: 1,
		marginLeft: 6,
		fontSize: 13,
		fontWeight: "500",
		lineHeight: "110%",
		color: theme.palette.text.main
	},
	countryBody: {
		display: "flex",
		gap: 10,

		"& div": {
			fontSize: 11,
			lineHeight: "110%",
			color: theme.palette.text.main,
			textAlign: "right",
			opacity: 0.5,
		}
	},
	countryDisabledMessage: {
		position: "absolute",
		top: 0, left: 0,
		zIndex: 3,
		width: "100%",
		height: "100%",
		borderRadius: 8,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 13,
		lineHeight: "100%",
		fontWeight: "600",
		color: theme.palette.primary.contrastText
	},

	skeleton: {
		height: "38px!important",
		transform: "initial!important",
		backgroundColor: `${theme.palette.background.secondary}!important`
	}
}));

export default Countries
