import React from "react";
import {
	Box,
	Button,
	SvgIcon,
	IconButton,
	LinearProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	ContentCopy as ContentCopyIcon
} from "@mui/icons-material";
import {getImage} from "../../../../../utils/image";
import theme from "../../../../../theme";
import {addToClipboard} from "../../../../../utils/clipboard";
import {notification, notificationTypes} from "../../../../../utils/notification";
import moment from "moment";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {cancenActiveNumber, getReceivedNumbers} from "../../../../../redux/stores/profile";
import getTrans from "../../../../../languages/getTrans";
import agent from "../../../../../agent/agent";

const NumberCard = (props) => {
	const {
		item
	} = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const isMultipleSms = Boolean(item?.multiple_sms);

	const [isBackdrop, setBackdrop] = React.useState(false);

	const [receivedSms, setReceivedSms] = React.useState([]);
	React.useEffect(() => {
		if (Boolean(item.multiple_sms)) {
			setReceivedSms([...item.multiple_codes])
		} else {
			setReceivedSms([item.code].filter((t) => !!t))
		}
	}, [item]);

	const handleCopy = async (string = '') => {
		await addToClipboard(string);
		notification({
			message: getTrans('common.Скопировано'),
			type: notificationTypes.success
		})
	};
	const handleCopyNumber = () => {
		let _str = String(item?.number || '');
		_str = _str.replace(item.country_phone_code, '');
		handleCopy(_str);
	}
	const handleCancelNumber = async () => {
		setBackdrop(true);
		const resCancel = await dispatch(cancenActiveNumber(item.id));
		setBackdrop(false);
	};

	const handleGetMoreSms = async () => {
		setBackdrop(true);
		const res = await agent.put(`/api/sms-users/retry-sms/${ item.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response?.data?.message || getTrans('errors.Ошибка сервера')}
		});
		if (res?.error) {
			setBackdrop(false);
			notification({
				message: res?.error,
				type: notificationTypes.error
			})
			return
		}
		await dispatch(getReceivedNumbers())
		setBackdrop(false);
		notification({
			type: notificationTypes.success,
			message: getTrans('balance_hash.Успешно')
		})
	}

	const _formatPhoneNumber = () => {
		const {number, country_phone_code} = item;
		return String(number).replace(country_phone_code, `<span>+${country_phone_code}</span> `)
	};

	return (
		<Box
			className={clsx({
				[classes.root]: true,
				"--loading": isBackdrop
			})}
		>

			<Box className={classes.service}>
				<Box className={classes.serviceIcon}>
					<img src={getImage(item?.service_icon, {width: 32, height: 32, fit: "fill"})}/>
				</Box>
				<Box className={classes.serviceName}>
					{item?.service_name || ""}
				</Box>
			</Box>

			<Box className={classes.number}>
				<Box className={classes.numberIcon}>
					<img src={getImage(item?.country_icon, {width: 32, height: 32, fit: "fill"})}/>
				</Box>
				<Box
					className={classes.numberName}
					dangerouslySetInnerHTML={{
						__html: _formatPhoneNumber()
					}}
				/>
				<IconButton className={classes.numberCopy} onClick={handleCopyNumber}>
					<SvgIcon>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19 16.25C19.6904 16.25 20.25 15.6904 20.25 15V5C20.25 4.30964 19.6904 3.75 19 3.75L9 3.75C8.30964 3.75 7.75 4.30964 7.75 5" stroke="currentColor" stroke-width="1.5"/>
							<path d="M16.25 19C16.25 19.6904 15.6904 20.25 15 20.25H5C4.30964 20.25 3.75 19.6904 3.75 19V9C3.75 8.30964 4.30964 7.75 5 7.75H15C15.6904 7.75 16.25 8.30964 16.25 9V19Z" stroke="currentColor" stroke-width="1.5"/>
						</svg>
					</SvgIcon>
				</IconButton>
			</Box>

			<WaitSmsIndicator
				end={item.timestamp_end}
				disabled={Boolean(receivedSms.length > 0) && Boolean(!isMultipleSms)}
			/>

			<ReceivedCodes codes={receivedSms}/>

			<CancelNumber
				start={item.timestamp}
				disabled={Boolean(receivedSms.length > 0)}
				onCancel={handleCancelNumber}
			/>
			<MoreButton
				disabled={Boolean(!isMultipleSms || item.status !== 1) || Boolean(receivedSms.length <= 0)}
				onMore={handleGetMoreSms}
			/>
		</Box>
	)
};

const WaitSmsIndicator = React.memo((props) => {
	const {end, disabled} = props;
	const classes = useStyles();
	let timeoutChangeDifference = null;

	const [percent, setPercent] = React.useState(0);
	const [difference, setDifference] = React.useState(0);
	const handleChangeDifference = () => {
		clearTimeout(timeoutChangeDifference);

		const localEnd = new Date(end * 1000).valueOf();
		const localStart = Date.now();
		const _difference = localEnd - localStart;
		if (_difference <= 0) {
			setDifference(0);
			return
		}

		setDifference(_difference);
		setPercent((1 - (_difference / 1200000)) * 100);

		timeoutChangeDifference = setTimeout(() => {
			handleChangeDifference()
		}, 1000);
	};
	React.useEffect(() => {
		handleChangeDifference();
	}, []);

	if (difference <= 0 || disabled) {
		return null
	}
	return (
		<Box className={classes.waitSmsIndicator}>
			<div className={classes.waitSmsIndicatorMessage}>
				{getTrans('my_numbers.Ожидаем СМС')} {moment(difference).add(new Date().getTimezoneOffset(), 'm').format("mm:ss")}
			</div>
			<LinearProgress
				value={percent}
				variant="determinate"
				className={classes.waitSmsIndicatorProgress}
			/>
		</Box>
	)
});
const CancelNumber = React.memo((props) => {
	const {
		start,
		disabled,
		onCancel
	} = props;
	const classes = useStyles();
	let timeoutCancelNumber = null;

	const [difference, setDifference] = React.useState(0);
	const handleChangeDifference = () => {
		clearTimeout(timeoutCancelNumber);

		const localStart = new Date().getTime();
		const localEnd = start * 1000 + (1.5 * 60 * 1000);
		const _difference = localEnd - localStart;
		if (_difference <= 0) {
			setDifference(0);
			return
		}

		setDifference(_difference);

		timeoutCancelNumber = setTimeout(() => {
			handleChangeDifference()
		}, 1000);
	};
	React.useEffect(() => {
		handleChangeDifference();
	}, []);

	if (disabled) {
		return null
	}
	return (
		<Box className={classes.cancelNumber}>
			<Button
				disabled={difference > 0}
				className={clsx({
					[classes.cancelNumberButton]: true,
					"--avaibled": Boolean(difference <= 0)
				})}
				onClick={onCancel}
			>{getTrans('common.Отмена')}</Button>
			{Boolean(difference > 0) && (
				<div className={classes.cancelNumberMessage}>
					{getTrans('my_numbers.Доступно через')} {moment(difference).add(new Date().getTimezoneOffset(), 'm').format('mm:ss')}
				</div>
			)}
		</Box>
	)
});
const ReceivedCodes = React.memo((props) => {
	const {codes} = props;
	const classes = useStyles();

	const handleCopy = async (string = '') => {
		await addToClipboard(string);
		notification({
			message: getTrans('common.Скопировано'),
			type: notificationTypes.success
		})
	};

	if (codes.length <= 0) {
		return null
	}
	return (
		<Box className={classes.receivedCodes}>
			{codes.map((code, index) => (
				<Box className={classes.receivedCodesItem}>
					<div className="--label">Sms №{index + 1}</div>
					<div className="--code">{code}</div>
					<IconButton
						className={classes.numberCopy}
						style={{marginLeft: 0}}
						onClick={handleCopy.bind(null, code)}
					>
						<SvgIcon>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M19 16.25C19.6904 16.25 20.25 15.6904 20.25 15V5C20.25 4.30964 19.6904 3.75 19 3.75L9 3.75C8.30964 3.75 7.75 4.30964 7.75 5" stroke="currentColor" stroke-width="1.5"/>
								<path d="M16.25 19C16.25 19.6904 15.6904 20.25 15 20.25H5C4.30964 20.25 3.75 19.6904 3.75 19V9C3.75 8.30964 4.30964 7.75 5 7.75H15C15.6904 7.75 16.25 8.30964 16.25 9V19Z" stroke="currentColor" stroke-width="1.5"/>
							</svg>
						</SvgIcon>
					</IconButton>
				</Box>
			))}
		</Box>
	)
});
const MoreButton = React.memo(({ disabled, onMore }) => {
	const classes = useStyles();

	if (disabled) {
		return null
	}
	return (
		<Box className={classes.cancelNumber}>
			<Button
				className={`${classes.cancelNumberButton} --avaibled`}
				onClick={onMore}
			>{getTrans('my_numbers.Еще смс')}</Button>
		</Box>
	)
});

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		overflow: "hidden",

		"&.--loading": {
			"&:after": {
				content: "''",
				position: "absolute",
				top: 0, left: 0,
				width: "100%",
				height: "100%",
			}
		}
	},

	service: {
		display: "flex",
		alignItems: "center",
		padding: 6,
		boxSizing: "border-box",
		background: theme.palette.background.secondary,
		borderRadius: 8
	},
	serviceIcon: {
		width: 26,
		height: 26,
		position: "relative",
		borderRadius: 4,
		overflow: "hidden",
		marginRight: 6,

		"& img": {
			position: "absolute",
			top: 0, left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	serviceName: {
		fontSize: 13,
		fontWeight: "500",
		lineHeight: "100%",
		color: theme.palette.text.main
	},

	number: {
		display: "flex",
		alignItems: "center",
		padding: 6,
		boxSizing: "border-box",
		background: theme.palette.background.secondary,
		borderRadius: 8,
		marginTop: 2
	},
	numberIcon: {
		width: 26,
		height: 26,
		position: "relative",
		borderRadius: 4,
		overflow: "hidden",
		marginRight: 6,

		"& img": {
			position: "absolute",
			top: 0, left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	numberName: {
		fontSize: 13,
		fontWeight: "500",
		lineHeight: "100%",
		color: theme.palette.text.main
	},
	numberCopy: {
		padding: 2,
		width: 24,
		height: 24,
		marginLeft: 8,

		"& svg": {
			width: "100%",
			height: "100%",
			color: theme.palette.secondary.main
		}
	},

	waitSmsIndicator: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 8,
		overflow: "hidden",
		position: "relative",
		padding: 12,
		boxSizing: "border-box",
		background: theme.palette.background.secondary,
		marginTop: 2,
		zIndex: 0
	},
	waitSmsIndicatorMessage: {
		fontSize: "13px",
		lineHeight: "13px",
		fontWeight: "500",
		textAlign: "center",
		color: theme.palette.text.main,
	},
	waitSmsIndicatorProgress: {
		position: "absolute",
		top: 0, left: 0,
		zIndex: -1,
		width: "100%",
		height: "100%",
		background: theme.palette.background.secondary,

		"& .MuiLinearProgress-bar": {
			background: theme.palette.secondary.main,
		}
	},

	cancelNumber: {
		display: "flex",
		alignItems: "center",
		gap: 10,
		marginTop: 12
	},
	cancelNumberButton: {
		background: "#767D8880",
		color: "white",

		"&.--avaibled": {
			background: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		}
	},
	cancelNumberMessage: {
		opacity: 0.5,

		fontSize: "11px",
		lineHeight: "11px",
		fontWeight: "500",
		color: "#767D88",
	},

	receivedCodes: {
		marginTop: 2,
		borderRadius: 8,
		background: theme.palette.background.secondary,
	},
	receivedCodesItem: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		padding: "8px 12px",
		boxSizing: "border-box",

		"& .--code": {
			color: theme.palette.text.main,
			fontSize: 16,
			lineHeight: "13px",
			fontWeight: "600"
		},
		"& .--label": {
			color: "#767D88",
			fontSize: 13,
			lineHeight: "16px",
			fontWeight: "500"
		},
		"& .--button": {},
	}
}));

export default NumberCard
