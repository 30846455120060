import agent from "../../agent/agent";
import {setItemFromLocalStorage} from "../../utils/localstorage";

const GET_USER = "profile/GET_USER";
const GET_BALANCE = "profile/GET_BALANCE";
const CHANGE_LOCALE = "profile/CHANGE_LOCALE";
const GET_RECEIVED_NUMBERS = "profile/GET_RECEIVED_NUMBERS";
const CHAGNE_LOADING_RECEIVED_NUMBERS = "profile/CHAGNE_LOADING_RECEIVED_NUMBERS";

const initStage = {
	user: null,
	balance: 0,
	locale: "en",
	currency: "rub",

	receivedNumbers: [],
	isLoadingReceivedNumbers: true,
};

export function getUser () {
	return async function (dispatch) {
		const { profile, token } = await agent.post('/api/tg-user-auth', {
			"chat-id": window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
			"params": window?.Telegram?.WebApp?.initData,
		}).then((res) => {
			return {
				token: res.data?.token || null,
				profile: res.data?.user || null,
			}
		}).catch((err) => {
			return null
		});
		agent.defaults.headers['Authorization'] = `Bearer ${ token }`;

		const balance = await agent.get('/api/sms-users/balance').then((res) => {
			return res.data
		}).catch(() => {
			return 0
		});

		dispatch({
			type: GET_USER,
			value: profile
		});
		dispatch({
			type: GET_BALANCE,
			value: balance
		});
	}
};
export function updateBalance () {
	return async function (dispatch) {
		const balance = await agent.get('/api/sms-users/balance').then((res) => {
			return res.data
		}).catch(() => {
			return 0
		});
		dispatch({
			type: GET_BALANCE,
			value: balance
		});
	}
}

export function changeLocale (locale) {
	// let apiHost = process.env.REACT_APP_EN_HOST_API;
	// if (locale === 'ru') {
	// 	apiHost = process.env.REACT_APP_RU_HOST_API;
	// }
	// agent.defaults['baseURL'] = apiHost;

	setItemFromLocalStorage('locale', locale);
	agent.defaults.headers['x-user-locale'] = locale;
	return {
		type: CHANGE_LOCALE,
		locale: locale
	}
}
export function getReceivedNumbers () {
	return async function (dispatch) {
		dispatch({ type: CHAGNE_LOADING_RECEIVED_NUMBERS, value: true });
		const res = await agent.get('/api/sms-users/active-numbers').then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		dispatch({
			type: GET_RECEIVED_NUMBERS,
			value: res
		});
	}
};
export function cancenActiveNumber (numberId) {
	return async function (dispatch) {

		const res = await agent.put(`/api/sms-users/cancel-number/${ numberId }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			return res
		}

		await dispatch(getReceivedNumbers());
		await dispatch(updateBalance());

		return true
	}
};

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_USER: {
			return {
				...state,
				user: action?.value
			}
		}
		case GET_BALANCE: {
			return {
				...state,
				balance: action?.value
			}
		}
		case CHANGE_LOCALE: {
			return {
				...state,
				locale: action?.locale
			}
		}
		case GET_RECEIVED_NUMBERS: {
			return {
				...state,
				receivedNumbers: action?.value,
				isLoadingReceivedNumbers: false
			}
		}
		case CHAGNE_LOADING_RECEIVED_NUMBERS: {
			return {
				...state,
				isLoadingReceivedNumbers: action?.value
			}
		}
		default:
			return state;
	}
}
