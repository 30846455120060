import React from "react";
import {
	Box,
	Grid,
	Button
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {getImage} from "../../../../../utils/image";
import {useHistory} from "react-router-dom";
import getTrans from "../../../../../languages/getTrans";

const PaymentCard = (props) => {
	const {
		item
	} = props;
	const classes = useStyles();
	const history = useHistory();

	const handleRoute = () => {
		history.push(`/top-up-balance/${ item.id }`)
	};

	return (
		<Box className={classes.root}>

			<Box className={classes.header}>
				<img src={getImage(item.image_id, { fit: "fill", height: 40 })}/>
				<Button
					size="small"
					color="secondary"
					variant="contained"
					onClick={handleRoute}
				>{getTrans('common.Выбрать')}</Button>
			</Box>

			<Box className={classes.name}>
				{ item.name }
			</Box>

			<Box className={classes.comment}>
				{ item.comment }
			</Box>

		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 16,
		boxSizing: "border-box",
		background: theme.palette.background.section
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12,

		"& img": {
			height: 40,
			objectFit: "contain",
		}
	},
	name: {
		marginBottom: 8,

		fontSize: "20px",
		fontWeight: "700",
		lineHeight: "16px",
		letterSpacing: "-0.02em",
		color: theme.palette.text.main
	},
	comment: {
		fontSize: 12,
		lineHeight: "15px",
		color: "#767D88",
	}
}));

export default PaymentCard
