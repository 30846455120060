import React from "react";
import {
	Slide,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	List,
	ListItemIcon,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class CustomSelectDialog extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			options: [],
			title: "",
			select: null,
			onSubmit: null,
			isOpen: null,
		}
	}

	open = ({ title, options, onSubmit }) => {
		this.setState({
			title: title || "Выберите",
			options: options || [],
			isOpen: true,
			onSubmit
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	change = (val) => {
		if (this.state.onSubmit) {
			this.state.onSubmit(val);
		}
		this.close();
	}

	render () {
		const {
			options,
			select,
			title,
			isOpen
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<Dialog
				open={isOpen}

				fullWidth
				fullScreen
				keepMounted

				TransitionComponent={Transition}

				classes={{root: classes.root}}

				onClose={this.close}
			>
				<DialogTitle>{ title }</DialogTitle>
				<DialogContent>
					<List className={classes.list}>
						{options.map((_option) => (
							<ListItemButton
								key={`ListItemButton-${ _option.value }`}
								onClick={this.change.bind(null, _option.value)}
							>
								<ListItemIcon>
									<img src={_option.icon}/>
								</ListItemIcon>
								<ListItemText>{ _option.label }</ListItemText>
							</ListItemButton>
						))}
					</List>
				</DialogContent>
			</Dialog>
		)
	}
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	root: {
		"& .MuiPaper-root": {
			height: "auto",
			borderRadius: "10px 10px 0 0"
		},
		"& .MuiDialog-container": {
			alignItems: "flex-end"
		},
		"& .MuiDialogTitle-root": {
			padding: "14px 12px",

			background: "#FB7800",
			fontSize: 18,
			lineHeight: "100%",
			color: "white",
			fontWeight: "600"
		},
		"& .MuiDialogContent-root": {
			padding: "0"
		},
	},
	list: {
		"& .MuiButtonBase-root": {
			padding: "10px 12px",
		},
		"& .MuiListItemIcon-root": {
			width: 24,
			height: 24,
			minWidth: "initial",
			marginRight: 12,

			"& img": {
				width: "100%",
				height: "100%",
				objectFit: "cover",
				objectPosition: "center",
				borderRadius: "100%"
			}
		},
		"& .MuiListItemText-root .MuiTypography-root": {
			fontSize: 16,
			fontWeight: "700",
			lineHeight: "100%",
		}
	}
});
CustomSelectDialog = withStyles(styles)(CustomSelectDialog);

export default CustomSelectDialog
