const getImage = (imageId, { width, height, fit, } = {}) => {

	let params = [
		imageId
	];
	if (width) {
		params.push(`w-${ width }`)
	}
	if (height) {
		params.push(`h-${ height }`)
	}
	if (fit) {
		params.push(`fit-${ fit }`)
	}

	return `https://7grizzlysms.com/api/storage/image/${ params.join('_') }.webp`
}

export {
	getImage
}
