import React from "react";
import {
	Box,
	Typography,
	ToggleButton,
	ToggleButtonGroup
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import getTrans from "../../../../../languages/getTrans";

const Head = (props) => {
	const {
		filter,
		onChange
	} = props;
	const classes = useStyles();

	const handleChangeType = (event, value) => {
		let _filter = {...filter};
		_filter.type = value;
		_filter.page = 1;
		onChange(_filter);
	}

	return (
		<Box className={classes.root}>
			<Typography className={classes.title}>
				{getTrans('history.История баланса')}
			</Typography>
			<ToggleButtonGroup
				color="primary"
				value={filter.type}
				exclusive
				className={classes.buttonsGroup}
				onChange={handleChangeType}
			>
				<ToggleButton value="incoming">{getTrans('history.Пополнения')}</ToggleButton>
				<ToggleButton value="outgoing">{getTrans('history.Расходы')}</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 12,
		boxSizing: "border-box",
		background: theme.palette.background.section
	},
	title: {
		marginBottom: 10,
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "100%",
		color: theme.palette.text.main,
	},
	buttonsGroup: {
		"& .MuiButtonBase-root": {
			padding: "6px 12px",
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
			fontSize: 15,
			color: theme.palette.text.sectionHead,
			lineHeight: "110%",
			textTransform: "initial",
			backgroundColor: "rgba(255,255,255,0.05)",

			"&.Mui-selected": {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
			}
		}
	}
}));

export default Head
