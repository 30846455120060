import React, {Component} from "react";
import {
	Grid
} from "@mui/material";
import {
	PaymentCard
} from "./components";

class TopUpBalance extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = async () => {
		await this.props.getPayments();
	}


	render () {
		const {payments} = this.props;

		return (
			<Grid container spacing={1}>
				{payments.map((payment) => (
					<Grid item xs={12}>
						<PaymentCard item={payment}/>
					</Grid>
				))}
			</Grid>
		);
	}
}

export default TopUpBalance
