import React from "react";
import {
	Box,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const WelcomeLoading = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>

			<img
				src={require("../../../assets/svg/logos/small-logo.svg").default}
				className={classes.logo}
			/>

			<Typography
				className={classes.message}
			>Произошла ошибка.<br/>Повторите попытку позднее.</Typography>

		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
		height: "100vh",
		background: theme.palette.background.section,
	},
	logo: {
		flex: 1,
		maxWidth: 124
	},
	message: {
		fontSize: 24,
		lineHeight: "140%",
		color: theme.palette.text.main,
		textAlign: "center"
	},
	circularProgress: {
		marginTop: -64,
		marginBottom: 64,

		"& .MuiCircularProgress-circle": {
			stroke: "#FB7800"
		}
	}
}));

export default WelcomeLoading
