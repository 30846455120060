import React from "react";
import {
	Provider
} from "react-redux";
import {
	StyledEngineProvider
} from "@mui/material";
import {
	createTheme,
	ThemeProvider
} from "@mui/material/styles";
import {
	ReactNotifications
} from 'react-notifications-component'
import {
	WebAppProvider
} from '@vkruglikov/react-telegram-web-app';
import theme from "./theme";
import MainRoute from "./route";
import store from "./redux/store";

const App = () => {
	React.useEffect(() => {
		window.Telegram.WebApp.ready();
		window.Telegram.WebApp.expand();
		window.Telegram.WebApp.disableVerticalSwipes();
		setTimeout(() => {
			handleDisabledScrollClose();
		}, 1000)
	}, []);

	const handleDisabledScrollClose = () => {
		const overflow = 100
		document.body.style.overflowY = 'hidden'
		document.body.style.marginTop = `${overflow}px`
		document.body.style.height = window.innerHeight + overflow + "px"
		document.body.style.paddingBottom = `${overflow}px`
		window.scrollTo(0, overflow);
	}

	const themeParams = window?.Telegram?.WebApp?.themeParams || JSON.parse("{\"accent_text_color\":\"#168acd\",\"bg_color\":\"#ffffff\",\"button_color\":\"#40a7e3\",\"button_text_color\":\"#ffffff\",\"destructive_text_color\":\"#d14e4e\",\"header_bg_color\":\"#ffffff\",\"hint_color\":\"#999999\",\"link_color\":\"#168acd\",\"secondary_bg_color\":\"#f1f1f1\",\"section_bg_color\":\"#ffffff\",\"section_header_text_color\":\"#168acd\",\"subtitle_text_color\":\"#999999\",\"text_color\":\"#000000\"}");

	theme.palette.background.paper = themeParams?.bg_color;
	theme.palette.background.header = themeParams?.header_bg_color;
	theme.palette.background.section = themeParams?.section_bg_color;
	theme.palette.background.secondary = themeParams?.secondary_bg_color;

	theme.palette.text.main = themeParams.text_color;
	theme.palette.text.link = themeParams.link_color;
	theme.palette.text.hint = themeParams.hint_color;
	theme.palette.text.accent = themeParams.accent_text_color;
	theme.palette.text.caption = themeParams.subtitle_text_color;
	theme.palette.text.sectionHead = themeParams.section_header_text_color;

	theme.palette.primary.main = themeParams.button_color;
	theme.palette.primary.hover = themeParams.button_color;
	theme.palette.primary.dark = themeParams.button_color;
	theme.palette.primary.light = themeParams.button_color;
	theme.palette.primary.contrastText = themeParams.button_text_color;

	theme.palette.secondary.main = themeParams.button_color;
	theme.palette.secondary.hover = themeParams.button_color;
	theme.palette.secondary.dark = themeParams.button_color;
	theme.palette.secondary.light = themeParams.button_color;
	theme.palette.secondary.contrastText = themeParams.button_text_color;

	theme.palette.error.main = themeParams.destructive_text_color;

	return (
		<WebAppProvider options={{smoothButtonsTransition: true}}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={createTheme(theme)}>
					<Provider store={store}>
						<ReactNotifications/>
						<MainRoute/>
					</Provider>
				</ThemeProvider>
			</StyledEngineProvider>
		</WebAppProvider>
	)
}

export default App
