import {combineReducers} from 'redux';

import config from './stores/config';
import profile from './stores/profile';
import services from './stores/services';
import payments from './stores/payments';
import currency from './stores/currency';
import countries from './stores/countries';

const rootReducer = combineReducers({
  config,
  profile,
  services,
  payments,
  currency,
  countries
});

export default rootReducer
