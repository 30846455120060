import React from "react";
import {
	Box,
	Grid,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	CheckCircle as CheckCircleIcon
} from "@mui/icons-material";
import moment from "moment";
import getTrans from "../../../../../languages/getTrans";

const ItemCard = (props) => {
	const {
		item,
		type
	} = props;
	const classes = useStyles();

	const _mainName = () => {
		if (item?.service?.name) {
			return item?.service?.name
		}
	};
	const _mainAmount = () => {
		if (+item.amount === 0) {
			return getTrans('common.Н/Д')
		}
		return `${item.amount} ₽`
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.datetime}>
				<span
					dangerouslySetInnerHTML={{
						__html: Boolean(item.timestamp) ? moment(item.timestamp * 1000).format("DD.MM.YYYY<br/>HH:mm:ss") : getTrans('common.Н/Д')
					}}
				/>
			</Box>
			<Box className={classes.main}>
				<span className="--left" dangerouslySetInnerHTML={{ __html: _mainName() }}/>
				<span className="--right" dangerouslySetInnerHTML={{ __html: _mainAmount() }}/>
			</Box>
			<Box className={classes.icon}>
				<CheckCircleIcon color="secondary" sx={{ fontSize: 24 }}/>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		gap: 2,
		"& > *": {
			padding: "8px 12px",
			boxSizing: "border-box",
			backgroundColor: theme.palette.background.section,

		}
	},
	datetime: {
		width: 92,
		fontSize: 13,
		lineHeight: "17px",
		color: theme.palette.text.main,

		"& span": {
			opacity: 0.6
		}
	},
	main: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		gap: 12,

		"& .--left": {
			flex: "auto",
			fontWeight: "600",
			fontSize: "13px",
			lineHeight: "150%",
			color: theme.palette.text.main,
		},
		"& .--right": {
			padding: "4px 8px",
			boxSizing: "border-box",
			borderRadius: 4,
			fontWeight: "600",
			fontSize: "14px",
			lineHeight: "100%",
			whiteSpace: "nowrap",
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.secondary.main,
		},
	},
	icon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}
}))

export default ItemCard
