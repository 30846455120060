import React from "react";
import {
	Box,
	Button,
	Checkbox,
	Backdrop,
	TextField,
	CircularProgress,
	FormControlLabel,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	notification,
	notificationTypes
} from "../../utils/notification";
import { Formik } from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import agent from "../../agent/agent";
import getTrans from "../../languages/getTrans";

const FormFeedBack = () => {
	const classes = useStyles();
	const profile = useSelector((state) => state?.profile?.user);

	const [isTerm, setTerm] = React.useState(true);
	const [isLoading, setLoading] = React.useState(false);

	const refFormik = React.createRef();
	const [formData, setFormData] = React.useState({
		email: "",
		message: "",
		nicknameTG: "",
	});
	const handleChangeFormData = ({ target }) => {
		if (!refFormik.current) {
			return
		}

		const { name, value } = target;
		let _formData = refFormik.current.values;
		_formData[name] = value;
		refFormik.current.setValues(_formData);
	}
	const handleSubmitFormData = async (values, formikProps) => {
		setLoading(true);

		const body = {
			"type": "Обратная связь",
			"name": profile?.email || "",
			"phone": profile?.phone || "",
			"surname": values?.nicknameTG || "",
			"message": values?.message,
			"email": values?.email,
			"source": "tg-app",
			"nophone": 1,
			"g-recaptcha-response": "g-recaptcha-response"
		};
		const res = await agent.post('/api/form/send', body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: getTrans('error.Ошибка сервера')}
		});
		if (res?.error) {
			setLoading(false);
			notification({message: res?.error, type: notificationTypes.error});
			return
		}

		formikProps.resetForm();
		setLoading(false);
		notification({
			type: notificationTypes.success,
			message: getTrans('feedback.Обращение успешно отправлено')
		})
	}

	return (
		<Formik
			innerRef={refFormik}
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={handleSubmitFormData}
		>{(params) => {
			const {
				values,
				errors,
				touched,
				handleSubmit,
			} = params;

			return (
				<Box className={classes.root}>
					<div className={classes.title}>
						{getTrans('feedback.Обратная связь')}
					</div>
					<TextField
						value={values.email}
						fullWidth={true}
						variant="outlined"
						name="email"
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
						placeholder={getTrans('feedback.Email')}
						className={classes.textField}
						onChange={handleChangeFormData}
					/>
					<Box mt={1}/>
					<TextField
						value={values.nicknameTG}
						fullWidth={true}
						variant="outlined"
						name="nicknameTG"
						error={Boolean(touched.nicknameTG && errors.nicknameTG)}
						helperText={touched.nicknameTG && errors.nicknameTG}
						placeholder={getTrans('feedback.Никнейм TG')}
						className={classes.textField}
						onChange={handleChangeFormData}
					/>
					<Box mt={1}/>
					<TextField
						rows={4}
						value={values.message}
						multiline={true}
						fullWidth={true}
						name="message"
						variant="outlined"
						error={Boolean(touched.message && errors.message)}
						helperText={touched.message && errors.message}
						placeholder={getTrans('feedback.Ваше сообщение')}
						className={classes.textField}
						onChange={handleChangeFormData}
					/>
					<Box mt={1}/>
					<FormControlLabel
						value={isTerm}
						checked={isTerm}
						control={<Checkbox />}
						label={<React.Fragment>
							<div
								dangerouslySetInnerHTML={{
									__html: getTrans('feedback.Политика со ссылкой')
								}}
							/>
						</React.Fragment>}
						className={classes.formControlLabel}
						onChange={(e, v) => setTerm(v)}
					/>
					<Box mt={1}/>
					<Button
						fullWidth={true}
						disabled={!isTerm}
						variant="contained"
						onClick={handleSubmit}
					>{getTrans('common.Отправить')}</Button>

					<Backdrop open={isLoading}>
						<CircularProgress/>
					</Backdrop>
				</Box>
			)
		}}</Formik>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "16px 12px",
		boxSizing: "border-box",
		background: theme.palette.background.section,
	},
	title: {
		marginBottom: 14,

		fontSize: 18,
		color: theme.palette.text.main,
		fontWeight: "600",
		lineHeight: "120%"
	},
	textField: {
		"& .MuiInputBase-root": {
			padding: 0
		}
	},
	formControlLabel: {
		"& .MuiFormControlLabel-label": {
			fontSize: 14,
			lineHeight: 1.2,

			"& a": {
				color: theme.palette.text.link,
				textDecoration: "underline"
			}
		}
	}
}));

const validationSchema = Yup.object().shape({
	email: Yup.string().email(getTrans('forms.Некорректный email')).required(getTrans('forms.Обязательно к заполнению')),
	message: Yup.string().required(getTrans('forms.Обязательно к заполнению')),
});

export default FormFeedBack
