import React, {Component} from "react";
import {
	Box,
	Button
} from "@mui/material";
import {notification, notificationTypes} from "../../../utils/notification";
import getTrans from "../../../languages/getTrans";
import agent from "../../../agent/agent";
import {withStyles} from "@mui/styles";
import clsx from "clsx";

class TopUpBalanceHash extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isShowRepeat: true,
			isSuccess: true,

			statusMessage: ""
		};
	}

	componentDidMount = async () => {
	  await this.getStatusPayment();
	}

	getStatusPayment = async () => {
		const hash = this.props?.match?.params?.hash || "";
		if (!hash) {
			this.props.history.push("/");
			notification({
				message: getTrans('balance_hash.Платеж не обнаружен'),
				type: notificationTypes.error,
			})
			return
		}

		const res = await agent.get(`/api/checkout/${ hash }`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		})
		if (!res) {
			this.props.history.push("/");
			notification({
				message: getTrans('balance_hash.Платеж не обнаружен'),
				type: notificationTypes.error,
			})
			return
		}

		let message = getTrans('balance_hash.В процессе');
		if (Boolean(res.payment_status)) {
			message = getTrans('balance_hash.Успешно');
		}

		await this.setState({
			statusMessage: message,
			isSuccess: res.payment_status,
			isShowRepeat: !Boolean(res.payment_status)
		})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			statusMessage,
			isSuccess,
			isShowRepeat
		} = this.state;
		console.log('this.props: ', this.props);

		return (
			<Box className={classes.root}>

				<Box className={classes.title}>
					Статус оплаты: <span className={clsx({
					['--error']: Boolean(!isSuccess),
					['--success']: Boolean(isSuccess),
				})}>{statusMessage}</span>
				</Box>

				{Boolean(isShowRepeat) && (
					<>
						<Box mt={2}/>
						<Button
							variant="contained"
							onClick={this.getStatusPayment}
						>
							{getTrans('balance_hash.Обновить')}
						</Button>
					</>
				)}

			</Box>
		);
	}
}

const styles = (theme) => ({
	root: {
		padding: "14px 12px",
		boxSizing: "border-box"
	},
	title: {
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		letterSpacing: "0.1rem",
		color: theme.palette.text.main,

		"& span": {
			fontSize: 18,
			fontWeight: "bold",
			"&.--error": {
				color: theme.palette.error.main,
			},
			"&.--success": {
				color: theme.palette.success.main,
			},
		},
	}
});
TopUpBalanceHash = withStyles(styles)(TopUpBalanceHash);

export default TopUpBalanceHash
