import React from "react";
import {
	Switch,
	Redirect,
	BrowserRouter
} from "react-router-dom";
import {
	RouteWithLayout
} from "../components";
import {
	ErrorContent,
	WelcomeLoading
} from "./components";
import {
	Faq as FaqPage,
	Settings as SettingsPage,
	Dashboard as DashboardPage,
	TopUpBalance as TopUpBalancePage,
	BalanceHistory as BalanceHistoryPage,
	NumbersReceived as NumbersReceivedPage,
	TopUpBalanceSlug as TopUpBalanceSlugPage,
	TopUpBalanceHash as TopUpBalanceHashPage
} from "../pages";
import MainLayout from "../layouts/Main";
import {compose} from "recompose";
import {connect} from "react-redux";
import {getServices} from "../redux/stores/services";
import {getCountries} from "../redux/stores/countries";
import {getConfig} from "../redux/stores/config";
import {changeLocale, getUser, updateBalance} from "../redux/stores/profile";
import {getCurrency} from "../redux/stores/currency";
import {getItemFromLocalStorage} from "../utils/localstorage";
import {dictionaries} from "../languages/getTrans";

const pages = [
	{
		path: '/',
		component: DashboardPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/settings',
		component: SettingsPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/faq',
		component: FaqPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/numbers-received',
		component: NumbersReceivedPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/top-up-balance',
		component: TopUpBalancePage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/top-up-balance/:id',
		component: TopUpBalanceSlugPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/top-up-balance/hash/:hash',
		component: TopUpBalanceHashPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
	{
		path: '/balance-history',
		component: BalanceHistoryPage,
		layout: MainLayout,
		rules: [],
		exact: true
	},
];

class Route extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isError: false,
			isLoading: true,
		};

		this.refBrowserRouter = React.createRef();
		this.timeoutUpdateBalance = null;
	}

	componentDidMount = async () => {
		await this.initConfig();

		await this.props.getUser();
		if (!this.props.profile) {
			await this.setState({
				isError: true,
				isLoading: false
			});
			return
		}

		await this.props.getConfig();
		await this.props.getCurrency();
		await this.props.getServices();
		await this.props.getCountries();
		await this.setState({ isLoading: false });

		this.callTgEvents();

		this.initStartParam();
		await this.updateBalance();
	}

	initConfig = async () => {
		const _localeUser = window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code || 'en';
		let locale = getItemFromLocalStorage('locale');
		if (!locale && dictionaries[_localeUser]) {
			locale = _localeUser;
		}

		if (!!locale) {
			this.props.changeLocale(locale);
		}
	};
	callTgEvents = () => {
		window.Telegram.WebApp.onEvent("backButtonClicked", () => {
			if (this.refBrowserRouter?.current?.history) {
				this.refBrowserRouter.current.history.goBack();
			}
		});
	}
	initStartParam = () => {
		const startParam = window?.Telegram?.WebApp?.initDataUnsafe?.start_param || "";
		if (!startParam) {
			return
		}

		let itemsInits = {};
		String(startParam || '').split("_").map((item) => {
			const [key, value] = item.split("=");
			itemsInits[key] = value;
		});

		if (itemsInits?.hash) {
			this.refBrowserRouter.current.history.push(`/top-up-balance/hash/${ itemsInits?.hash }`);
			return;
		}
		if (itemsInits?.payments) {
			this.refBrowserRouter.current.history.push('/balance-history');
			return;
		}
	}

	updateBalance = async () => {
		clearTimeout(this.timeoutUpdateBalance);
		await this.props.updateBalance();
		this.timeoutUpdateBalance = setTimeout(async () => {
			await this.updateBalance();
		}, (20 * 1000))
	}

	render () {
		const {
			isError,
			isLoading
		} = this.state;

		if (isLoading) {
			return (
				<WelcomeLoading/>
			)
		}
		if (isError) {
			return (
				<ErrorContent/>
			)
		}
		return (
			<BrowserRouter
				ref={this.refBrowserRouter}
				forceRefresh={false}
			>
				<MainLayout>
					<Switch>
						{pages.map((page, index) => (
							<RouteWithLayout
								key={`RouteWithLayout-${ page.path }`}
								{...page}
							/>
						))}
					</Switch>
				</MainLayout>
			</BrowserRouter>
		)
	}
}

export default compose(
	connect(
		state => ({
			profile: state?.profile?.user
		}),
		dispatch => ({
			getUser: () => dispatch(getUser()),
			getConfig: () => dispatch(getConfig()),
			getCurrency: () => dispatch(getCurrency()),
			getServices: () => dispatch(getServices()),
			getCountries: () => dispatch(getCountries()),
			updateBalance: () => dispatch(updateBalance()),
			changeLocale: (val) => dispatch(changeLocale(val)),
		}),
	),
)(Route);
