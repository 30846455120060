import agent from "../../agent/agent";

const GET_CONFIG = "config/GET_CONFIG";

const initStage = {
	config: {},
};

export function getConfig () {
	return async function (dispatch) {

		const res = await agent.get('/api/configs').then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});

		dispatch({
			type: GET_CONFIG,
			value: res
		})

	}
}

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_CONFIG: {
			return {
				...state,
				config: action?.value || {}
			}
		}
		default:
			return state;
	}
}
