import React from "react";
import {
	Box,
	Grid,
	Button,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Link,
	useHistory,
	useRouteMatch
} from "react-router-dom";
import clsx from "clsx";
import CustomSelectDialog from "./CustomSelectDialog";
import {useDispatch, useSelector} from "react-redux";
import {convertorNumber} from "../../../utils/convertor";
import getTrans from "../../../languages/getTrans";
import {changeLocale} from "../../../redux/stores/profile";

const Header = ({menuList}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const refCustomSelectDialog = React.createRef();

	const _locale = useSelector((state) => state.profile.locale);
	const [selectedLocale, setSelectedLocale] = React.useState(() => {
		if (_locale === 'ru') {
			return {
				icon: require('../../../assets/svg/countries/rus.svg').default,
				label: 'Русский'
			}
		}
		if (_locale === 'en') {
			return {
				icon: require('../../../assets/svg/countries/en.svg').default,
				label: 'English'
			}
		}
	}, []);

	const handleChangeCurrency = () => {
		refCustomSelectDialog.current.open({
			title: getTrans('header.Выбор валюты'),
			options: [
				{label: 'RUB', icon: require('../../../assets/svg/currencies/rub.svg').default, value: 'rub'},
			]
		})
	}
	const handleChangeLanguage = (locale) => {
		if (!locale) {
			refCustomSelectDialog.current.open({
				title: getTrans('header.Выбор языка'),
				options: [
					{label: 'Русский', icon: require('../../../assets/svg/countries/rus.svg').default, value: 'ru'},
					{label: 'English', icon: require('../../../assets/svg/countries/en.svg').default, value: 'en'},
				],
				onSubmit: handleChangeLanguage.bind(null)
			});
			return
		}

		dispatch(changeLocale(locale));
		window.location.reload();
	}

	return (
		<>
			<Box className={classes.header}>
				<Box className={classes.headerTop}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Grid container spacing={1}>
								<Grid item>
									<Button
										className={classes.buttonSelect}
										onClick={handleChangeLanguage.bind(null, null)}
									>
										<img src={selectedLocale.icon} className="--icon"/>
										{selectedLocale.label}
										<img src={require("../../../assets/svg/header/arrow-down.svg").default} className="--arrow"/>
									</Button>
								</Grid>
								<Grid item>
									<Button
										className={classes.buttonSelect}
										onClick={handleChangeCurrency}
									>
										<img src={require("../../../assets/svg/currencies/rub.svg").default} className="--icon"/>
										RUB
										<img src={require("../../../assets/svg/header/arrow-down.svg").default} className="--arrow"/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<BalanceItem/>
						</Grid>
					</Grid>
				</Box>
				<Box className={classes.headerBottom}>
					{(menuList || []).map((menuItem, index) => (
						<Button
							key={`ButtonNavigation-${ menuItem.path }`}
							to={menuItem.path}
							className={clsx({
								[classes.buttonMenu]: true
							})}
							component={Link}
						>
							{/*<img src={menuItem.Icon}/>*/}
							{ menuItem.label }
						</Button>
					))}
				</Box>
			</Box>
			<CustomSelectDialog ref={refCustomSelectDialog}/>
		</>
	)
};
const BalanceItem = React.memo(() => {
	const classes = useStyles();
	const balance = useSelector((state) => state.profile.balance || 0);

	return (
		<Box className={classes.balanceInfo}>
			<div className="--value">
				{convertorNumber(balance, 'auto', ',')}₽
			</div>
			<Button
				component={Link}
				to="/top-up-balance"
				className={classes.buttonSelect}
			>
				{getTrans('header.Пополнить')}
				<img src={require("../../../assets/svg/header/plus.svg").default} className="--icon"/>
			</Button>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	header: {
		background: "#F47500",
		overflow: "hidden",
		position: "relative"
	},
	headerTop: {
		background: "#FB7800",
		boxShadow: "0px 4px 8px 0px #0000000D",
		padding: "7px 8px",
		boxSizing: "border-box"
	},
	headerBottom: {
		display: "flex",
		gap: 2,
		padding: "7px 8px",
		boxSizing: "border-box",

		"& .MuiButtonBase-root": {
			"&:last-child": {
				borderRadius: "2px 8px 8px 2px"
			},
			"&:first-child": {
				borderRadius: "8px 2px 2px 8px"
			}
		}
	},

	buttonMenu: {
		display: "flex",
		alignItems: "center",
		gap: 4,
		padding: "8px 6px",
		boxSizing: "border-box",
		background: "#FF8514",
		border: "2px solid #FF8514",
		height: 36,
		borderRadius: 2,

		fontSize: 14,
		lineHeight: "100%",
		color: "white",
		fontWeight: "700",

		"@media(max-width: 420px)": {
			fontSize: 12,
		}
	},
	buttonMenuActive: {
		background: "#F39E1E",
		borderColor: "#FFB800",
		"& img": {
			opacity: 0.7
		}
	},

	buttonSelect: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		padding: "7px 8px",
		boxSizing: "border-box",
		background: "#E26D00",
		borderRadius: 10,

		fontSize: 12,
		lineHeight: "100%",
		color: "white",
		fontWeight: 600,

		"& .--icon": {
			width: 15,
			height: 15,
			objectFit: "cover",
			borderRadius: "100%",
			objectPosition: "absolute",
		},

		"@media(max-width: 420px)": {
			padding: "7px 6px",
			fontSize: 11,
		}
	},

	balanceInfo: {
		display: "flex",
		alignItems: "center",
		gap: 8,

		"& .--label": {
			fontSize: 15,
			lineHeight: "100%",
			color: "rgba(255,255,255,0.5)",
			fontWeight: "600",
		},
		"& .--value": {
			fontSize: 18,
			lineHeight: "100%",
			color: "rgba(255,255,255,1)",
			fontWeight: "600",
		},

		"@media(max-width: 420px)": {
			gap: 4,
			"& .--label": {
				fontSize: 12,
			},
			"& .--value": {
				fontSize: 14,
			},
			"& button": {
				borderRadius: 4,
			},
			"& button img": {
				display: "none",
			}
		}
	}
}));

export default Header
