import React, {Component} from "react";
import {
	InDeveloper
} from "../../../components";

class Settings extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<InDeveloper/>
		);
	}
};

export default Settings
