const addToClipboard = (string) => {
	if (window.clipboardData && window.clipboardData.setData) {
		return window.clipboardData.setData("string", string);
	}
	else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
		var textarea = document.createElement("textarea");
		textarea.textContent = string;
		textarea.style.position = "fixed";
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand("copy");  // Security exception may be thrown by some browsers.
		}
		catch (ex) {
			return prompt("Copy to clipboard: Ctrl+C, Enter", string);
		}
		finally {
			document.body.removeChild(textarea);
		}
	}
}

export {
	addToClipboard
}
