import React from "react";
import {
	Box,
	Grid,
	Link,
	Drawer,
	Button,
	Popover,
	Container,
	Typography,

	MenuList,
	MenuItem,
	ListItemText
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Help as HelpIcon,
	Menu as MenuIcon,
	Home as HomeIcon,
	Settings as SettingsIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
	AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon
} from "@mui/icons-material";
import {
	BackButton
} from "@vkruglikov/react-telegram-web-app";
import {
	useSelector
} from "react-redux";
import PopupState, {
	bindTrigger,
	bindPopover,
} from "material-ui-popup-state";
import {convertorNumber} from "../utils/convertor";
import {useHistory, useRouteMatch} from "react-router-dom";
import {convertCurrencyToCurrency, getCurrecyCode} from "../utils/currency";
import clsx from "clsx";
import {
	Header
} from "./components";
import getTrans from "../languages/getTrans";

const Main = (props) => {
	const {
		children,
		routeRef
	} = props;
	const classes = useStyles();

	React.useEffect(() => {
		if (['', '/'].includes(routeRef?.history?.location?.pathname)) {
			window.Telegram.WebApp.BackButton.hide()
		} else {
			window.Telegram.WebApp.BackButton.show()
		}
	}, [routeRef?.history?.location?.pathname]);

	const getMenuList = () => {
		return [
			{label: getTrans('header.Главная'), path: '/', Icon: require('../assets/svg/header/home.svg').default},
			{label: getTrans('header.Мои номера'), path: '/numbers-received', Icon: require('../assets/svg/header/my-numbers.svg').default},
			{label: getTrans('header.Поддержка'), path: '/faq', Icon: require('../assets/svg/header/faq.svg').default},
			{label: getTrans('header.История'), path: '/balance-history', Icon: require('../assets/svg/header/history.svg').default},
		]
	}

	return (
		<Box className={classes.root}>
			<Header
				menuList={getMenuList()}
				routeRef={routeRef}
				activePath={routeRef?.current?.history?.location?.pathname}
			/>

			<Box className={classes.rootBody}>
				{children}
			</Box>
		</Box>
	)
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100vh",
		background: theme.palette.background.secondary
	},
	rootHead: {},
	rootBody: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		overflow: "auto"
	},
	rootFooter: {},
}));

export default Main
