import agent from "../../agent/agent";

const GET_PAYMENTS = "payments/GET_PAYMENTS";
const CHANGE_LOADING = "payments/CHANGE_LOADING";

const initStage = {
	payments: [],
	isLoading: true
};

export function getPayments () {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADING, value: true });
		const _payments = await agent.get("/api/payments").then((res) => {
			return res.data
		}).catch(() => {
			return []
		});

		dispatch({
			type: GET_PAYMENTS,
			value: _payments
		})
	}
}

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_PAYMENTS: {
			return {
				...state,
				payments: action?.value || [],
				isLoading: false
			}
		}
		case CHANGE_LOADING: {
			return {
				...state,
				isLoading: action?.value
			}
		}
		default:
			return state;
	}
}
