import React, {Component} from "react";
import {
	Box,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Services as ServicesComponent,
	Countries as CountriesComponent
} from "./components";
import {updateBalance} from "../../../redux/stores/profile";
import agent from "../../../agent/agent";
import {notification, notificationTypes} from "../../../utils/notification";
import getTrans from "../../../languages/getTrans";

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isBackdrop: false
		};
	}

	componentDidMount = () => {
	}

	buyNumber = async (country) => {
		await this.setState({ isBackdrop: true });

		const price = Number.parseFloat(country?.price || 0);

		// Обновление баланса пользователя
		await this.props.updateBalance();
		const balance = this.props.balance;

		// Если баланс пользователя меньше отправляем пополнить баланс
		if (balance < price) {
			await this.setState({ isBackdrop: false });
			this.props.history.push("/top-up-balance")
			return
		}

		// Покупка номера
		const resBuyNumber = await agent.get(`/api/sms-users/get-number/${ country?.country_external_id }/${ country.service_external_id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err?.response || true}
		});
		if (resBuyNumber?.error) {
			await this.setState({ isBackdrop: false });
			notification({
				type: notificationTypes.error,
				message: resBuyNumber?.error?.data?.message || getTrans('errors.Ошибка сервера')
			})
			return
		}

		await this.props.updateBalance();
		await this.props.getReceivedNumbers();
		await this.setState({ isBackdrop: false });

		this.props.history.push("/numbers-received");
		notification({
			type: notificationTypes.success,
			message: getTrans('home.Номер успешно приобретен')
		})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			isBackdrop
		} = this.state;

		return (
			<Box className={classes.root}>
				<Box mt={1}/>
				<ServicesComponent/>
				<Box mt={1}/>
				<CountriesComponent onBuyNumber={this.buyNumber}/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		);
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
	}
};
Dashboard = withStyles(styles)(Dashboard);

export default Dashboard
