import React from "react";
import {
	Box,
	Grid,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {useSelector} from "react-redux";
import getTrans from "../../languages/getTrans";

const PersonalInformationSmall = () => {
	const classes = useStyles();
	const profile = useSelector((state) => state?.profile?.user);

	return (
		<Box className={classes.root}>
			<Typography
				className={classes.title}
				variant="sectionTitle"
			>{getTrans('header.Персональные данные')}</Typography>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<div className={classes.itemLabel}>{getTrans('header.Ваш e-mail')}</div>
					<div className={classes.itemValue}>{ profile?.email || getTrans('common.Н/Д') }</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.itemLabel}>{getTrans('header.Ваше имя')}</div>
					<div className={classes.itemValue}>{ profile?.name || getTrans('common.Н/Д') }</div>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "14px 12px",
		boxSizing: "border-box",
		background: theme.palette.background.section
	},
	title: {
		display: "block",
		marginBottom: "10px!important",
	},

	itemLabel: {
		marginBottom: 4,
		fontSize: 12,
		fontWeight: "500",
		lineHeight: "100%",
		color: theme.palette.text.main,
		opacity: 0.5
	},
	itemValue: {
		fontSize: 14,
		lineHeight: "100%",
		fontWeight: "600",
		color: theme.palette.secondary.main,
	},
}));

export default PersonalInformationSmall
