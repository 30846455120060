import {createTheme} from '@mui/material/styles';

import components from './overrides';
import {
  palette,
  typography,
  breakpoints
} from "./common";

// const theme = createTheme({
//   palette,
//   breakpoints,
//   components,
// });

const theme = {
  palette,
  typography,
  breakpoints,
  components,
};

export default theme;
