import agent from "../../agent/agent";

const GET_CURRENCIES = "currency/GET_CURRENCIES";

const initStage = {
	currencies: {
		usd: 0
	}
};

export function getCurrency () {
	return async function (dispatch) {
		const _usdRate = await agent.get('/api/currency/get-usd-rate').then((res) => {
			return res.data
		}).catch(() => {
			return 0
		});

		dispatch({
			type: GET_CURRENCIES,
			currencies: {
				usd: _usdRate
			}
		})
	}
}

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_CURRENCIES: {
			return {
				...state,
				currencies: action?.currencies
			}
		}
		default:
			return state;
	}
}
