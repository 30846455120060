import store from "../redux/store";
import russian from "./dictionaries/ru-RU.json";
import english from "./dictionaries/en-EN.json";

const dictionaries = {
	"ru": russian,
	"en": english,
};

const allTranslations = (key, props) => {
	const state = store.getState();

	const languageSelect = state.profile?.locale;
	const languageDefault = "en";

	if (!key) {
		return "key-not-found"
	}

	const wordbook = dictionaries[languageSelect];
	const defaultWordbook = Boolean(Object.keys(languageDefault).length > 0) ? languageDefault : {};
	const defaultWordbookLocale1 = dictionaries["en"];
	const defaultWordbookLocale2 = dictionaries["en"];

	let message = getText(key, wordbook, defaultWordbook, defaultWordbookLocale1, defaultWordbookLocale2);

	if (!props) {
		return message
	}

	Object.keys((props)).map((key) => {
		message = message.replace(`{{${key}}}`, props[key]);
	});

	return message;
};
const getText = (key, wordbook, defaultWordbook, defaultWordbookLocale1, defaultWordbookLocale2) => {
	return key.replace(/\[([^\]]+)]/g, '.$1').split('.').reduce(function (o, p) {
		if (!o || !o[p]) {
			return key.replace(/\[([^\]]+)]/g, '.$1').split('.').reduce(function (o, p) {
				if (!o || !o[p]) {
					return key.replace(/\[([^\]]+)]/g, '.$1').split('.').reduce(function (o, p) {
						if (!o || !o[p]) {
							return key.replace(/\[([^\]]+)]/g, '.$1').split('.').reduce(function (o, p) {
								if (!o || !o[p]) {
									return key
								}

								return o[p];
							}, defaultWordbookLocale2)
						}

						return o[p];
					}, defaultWordbookLocale1)
				}

				return o[p];
			}, defaultWordbook)
		}

		return o[p];
	}, wordbook);
}

export default allTranslations;
export {
	dictionaries
}
