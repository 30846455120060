import {getCountries} from "./countries";
import agent from "../../agent/agent";
import dictionaryRuEn from "../../constants/dictionary-ru-en.json";
import dictionaryEnRu from "../../constants/dictionary-en-ru.json";
import {getItemFromLocalStorage, setItemFromLocalStorage} from "../../utils/localstorage";

const GET_SERVICES = "profile/GET_SERVICES";
const CHANGE_SELECTED_SERVICE = "profile/CHANGE_SELECTED_SERVICE";

const initStage = {
	services: [],

	selectedService: null,

	isLoadingServices: true,
};

export function getServices () {
	return async function (dispatch, getState) {

		const _getTranscriptionRu = (dictionary, word = '') => {
			let _arr = word.split('');
			_arr = _arr.map((t) => dictionary[t]);
			return _arr.join('')
		};
		let services = await agent.get('/api/service/get-all').then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		services = [...(services || [])].map((service) => {
			const name = String(service?.name || '').toLocaleLowerCase();
			return {
				...service,
				nameTranscriptionRuEn: _getTranscriptionRu(dictionaryRuEn, name),
				nameTranscriptionEnRu: _getTranscriptionRu(dictionaryEnRu, name),
			}
		});

		let selectedService = getItemFromLocalStorage('selectedService');
		if (!selectedService) {
			const config = getState()?.config?.config;
			selectedService = String(config?.var?.['Сервис выбора по умолчанию'])
		}

		dispatch({
			type: GET_SERVICES,
			value: services
		});
		dispatch({
			type: CHANGE_SELECTED_SERVICE,
			value: selectedService
		});
	}
}
export function changeSelectedService (serviceId) {
	return async function (dispatch) {
		setItemFromLocalStorage('selectedService', serviceId);
		dispatch({ type: CHANGE_SELECTED_SERVICE, value: serviceId });
		dispatch(getCountries())
	}
}

export default function ProfileState (state = initStage, action = {}) {
	switch (action.type) {
		case GET_SERVICES: {
			return {
				...state,
				services: action?.value || [],
				isLoadingServices: false
			}
		}
		case CHANGE_SELECTED_SERVICE: {
			return {
				...state,
				selectedService: action?.value || null
			}
		}
		default:
			return state;
	}
}
