export default {
	styleOverrides: {
		root: ({ ownerState, theme }) => ({
			borderRadius: 12,
			height: "auto",
			border: "1px solid #3C4555",

			color: theme.palette.text.main,

			"& .MuiOutlinedInput-notchedOutline": {
				border: "none"
			},
			"& .MuiOutlinedInput-input": {
				padding: "10px 12px",
				height: "auto",
			},
			"& .MuiInputBase-inputMultiline": {
				padding: "10px 12px"
			}
		})
	}
}
