// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Dashboard from './Dashboard';
import {getReceivedNumbers, updateBalance} from "../../../redux/stores/profile";

export default compose(
  connect(
    state => ({
      balance: state?.profile?.balance || 0
    }),
    dispatch => ({
      updateBalance: () => dispatch(updateBalance()),
      getReceivedNumbers: () => dispatch(getReceivedNumbers()),
    }),
  ),
)(Dashboard);
