// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import TopUpBalanceSlug from './TopUpBalanceSlug';
import {getPayments} from "../../../redux/stores/payments";

export default compose(
  connect(
    state => ({
      user: state?.profile?.user,
      locale: state?.profile?.locale,
      payments: state.payments.payments || [],

    }),
    dispatch => ({
      getPayments: () => dispatch(getPayments()),
    }),
  ),
)(TopUpBalanceSlug);
