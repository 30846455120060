import MuiButton from "./MuiButton";
import MuiInputBase from "./MuiInputBase";
import MuiTextField from "./MuiTextField";
import MuiTypography from "./MuiTypography";
import MuiOutlinedInput from "./MuiOutlinedInput";

export default {
	MuiButton,
	MuiInputBase,
	MuiTextField,
	MuiTypography,
	MuiOutlinedInput,
}
