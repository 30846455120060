export default {
	styleOverrides: {
		sectionTitle: ({ ownerState, theme }) => ({
			fontWeight: "600",
			fontSize: "18px",
			lineHeight: "100%",
			color: theme.palette.text.main,
		}),
	}
}
